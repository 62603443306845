import {motion, AnimatePresence} from "framer-motion";
import { useState, createRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import google_meet from "../static/img/google_meet_icon.png"
import {variant_form, variant_center, variant_line} from "./const";

import MeetingPage from "./meetings";
import "../static/css/contact.css"
import 'animate.css/animate.min.css';


const recaptchaRef = createRef();
const Contact = ({createNotification, updateScroll}) => {
    const [captchaToken, setCaptchaToken] = useState(null);
    const [form_state,setForm_State] = useState({
        "firstName": "",
        "lastName" : "",
        "email" : "",
        "organization" : "",
        "message" : "",
    })
    const [blurBackground, toggleBlur] = useState(false);
    // const captcha_host = "http://localhost:8000/api/captcha/";
    // const contact_host = "http://localhost:8000/contact/";
    const contact_host = "https://api.haoxuanliu.com/backend/contact/";

    async function onCaptcha(value){
        console.log("Captcha value:", value);
        if (value === null){
            setCaptchaToken(null); // session expired
            console.log("captcha session expired");
            return;
        }
        else{
            setCaptchaToken(value);
            console.log("user has completed captcha");
        }
    }
    async function handleSubmit(event){
        event.preventDefault();
        // generic notification template
        let notif_obj = {
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 4000,
                onScreen: true,
            },
            width: 600,
            pauseOnHover: true
        }
        // verify that the user is allowed to submit
        if (captchaToken !== null){
            console.log("submitting user form");
            // create json with the form information
            let request_body = form_state; // may be wrong since form_state is react state
            request_body["token"] = captchaToken; 
            let response = await fetch(contact_host, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify(request_body),
            });
            let res_json = await response.json();
            // success or fail:
            let res_message = res_json["body"]
            // create a notification based on response
            if (res_message === "Success"){
                notif_obj["title"] = "Success!";
                notif_obj["message"] = "Form submitted successfully. A confirmation has been sent to your email address";
                notif_obj["type"] = "success";
                // clear the inputs
                setForm_State({
                    "firstName": "",
                    "lastName" : "",
                    "email" : "",
                    "organization" : "",
                    "message" : "",
                })
            }
            else{
                notif_obj["title"] = "Error:";
                notif_obj["message"] = res_message;
                notif_obj["type"] = "danger";
            }
            createNotification(notif_obj);
            console.log("event target is ", event.target)
            recaptchaRef.current.reset();
            setCaptchaToken(null);
        }
        else{
            // display error message
            notif_obj["title"] = "Error:";
            notif_obj["message"] = "Please complete the Captcha challenge to verify your identity";
            notif_obj["type"] = "danger";
            createNotification(notif_obj);
        }
    }
    const handleChange = (evt) => {
        const target_element = evt.target.name;
        if (target_element === "firstName"){
            setForm_State({
                ...form_state,
                "firstName": evt.target.value,
            })
        }
        else if (target_element === "lastName"){
            setForm_State({
                ...form_state,
                "lastName": evt.target.value,
            })
        }
        else if (target_element === "email"){
            setForm_State({
                ...form_state,
                "email": evt.target.value,
            })
        }
        else if (target_element === "organization"){
            setForm_State({
                ...form_state,
                "organization": evt.target.value,
            })
        }
        else{
            // target_element === "message"
            setForm_State({
                ...form_state,
                "message": evt.target.value,
            })
        }
    }

    return (
        <motion.div className="contact">
            <motion.hr variants={variant_line} initial="initial_top" animate="animate" exit="exit_top" className="divider top-line"></motion.hr>
            <motion.hr variants={variant_line} initial="initial_bottom" animate="animate" exit="exit_bottom" className="divider bottom-line"></motion.hr>
            <motion.div className="contact-header" variants={variant_center} 
                initial="initial_left"
                animate="animate_delayed"
                exit="exit_left"
            >
                <p className="contact-title">Contact Me</p>
            </motion.div>
            <motion.form variants={variant_form} className="contact-form" onSubmit={handleSubmit} 
                initial="initial_right"
                animate="animate"
                exit="exit_right"
            >
                <motion.div variants={variant_center} className="form-field">
                    <p className="form-field-label required">
                        First Name
                    </p>
                    <input type="text" name="firstName" className="form-field-input" value={form_state["firstName"]} placeholder="Enter your first name" placeholderTextColor='black' onChange={handleChange} required/>
                    {/* <div>
                        <p className="form-field-error form-field-error-hidden">Please enter your first name</p>
                    </div> */}
                </motion.div>
                <motion.div variants={variant_center} className="form-field">
                    <p className="form-field-label required">
                        Last Name
                    </p>
                    <input type="text" name="lastName" className="form-field-input" value={form_state["lastName"]} placeholder="Enter your last name" onChange={handleChange} required/>
                    {/* <div>
                        <p className="form-field-error form-field-error-hidden">Please enter your first name</p>
                    </div> */}
                </motion.div>
                <motion.div variants={variant_center} className="form-field">
                    <p className="form-field-label required">
                        Email
                    </p>
                    <input type="text" name="email" className="form-field-input" value={form_state["email"]} placeholder="your@your.email" onChange={handleChange} required/>
                    {/* <div>
                        <p className="form-field-error form-field-error-hidden">Please enter your first name</p>
                    </div> */}
                </motion.div>
                <motion.div variants={variant_center} className="form-field">
                    <p className="form-field-label required">
                        Organization
                    </p>
                    <input type="text" name="organization" className="form-field-input" value={form_state["organization"]} placeholder="Name of your organization" onChange={handleChange} required/>
                    {/* <div>
                        <p className="form-field-error form-field-error-hidden">Please enter your first name</p>
                    </div> */}
                </motion.div>
                <motion.div variants={variant_center} className="form-field">
                    <p className="form-field-label">
                        Message:
                    </p>
                    <textarea className="form-field-input form-message" placeholder="What brought you here? Perhaps provide something information about yourself!"
                        name="message" value={form_state["message"]} onChange={handleChange}/>
                    {/* <div>
                        <p className="form-field-error form-field-error-hidden">Please enter your first name</p>
                    </div> */}
                </motion.div>
                {/* form-field to enforce flex box */}
                <motion.div variants={variant_center} className="form-field">

                </motion.div>
                <motion.div variants={variant_center} className="form-field schedule-field">
                    <p className="form-field-label">
                        Meeting:
                    </p>
                    <div className="schedule-button-container" onClick={() => {toggleBlur(true); updateScroll(false)}} >
                        <img className="google-meet" src={google_meet} alt="google_meet"></img>
                        {/* <i class="fa fa-video-camera"></i> */}
                        <div className="schedule-button">
                            Schedule Meeting
                        </div>
                    </div>
                </motion.div>
                <motion.div variants={variant_center} className="form-field captcha-field">
                    <ReCAPTCHA className="captcha" ref={recaptchaRef} sitekey="6Ld3460bAAAAAEyhIa20DldNRxLHwKbvaieqhig6" onChange={onCaptcha} required/>
                </motion.div> 
                <motion.div variants={variant_center} className="form-field submit-button-container">
                    <input type="submit" value="Submit &rarr;" className="submit-button"></input>
                </motion.div>
            </motion.form>
            <AnimatePresence>
                { blurBackground && 
                    <MeetingPage toggleBlur={toggleBlur} formState={form_state} 
                        createNotification={createNotification} updateScroll={updateScroll}
                    />
                }
            </AnimatePresence>
        </motion.div>
    )
}

export default Contact
