
import {motion} from "framer-motion";
import { useState} from "react";
import {Link} from "react-router-dom";
import {items, text_block, image} from "./const";

function Chat({updateScroll}) {
    const [exitDirection, setExitDirection] = useState("vertical");

    const handleClick = (evt) =>{
        updateScroll(false);
        setExitDirection("horizontal");
    }
    // const exitDir = exitDirection.current;
    console.log(`exit dir is ${exitDirection}`);
    return (
        <motion.div className="flex_container chat"
            initial="hidden"
            animate="show"
            exit="exit"
        >
            <motion.div variants={text_block} className="chatText">
                <motion.div variants={items} className="chatContainer">
                    <motion.h4 className="name chatName" style={{"opacity":0.7}}>Meet</motion.h4>
                    <motion.span className="bottom-span"><motion.h4 className="name chatName" style={{"opacity":0.7}}>Ida</motion.h4></motion.span>
                </motion.div> 
                {/* <div className="lines">
                    <span className="underline"></span>
                    <span className="underline"></span>
                </div> */}
                {/* <div className="button-container">
                    <Link to="/Ida" className="button">Interact with Ida</Link>
                </div> */}
                <motion.p className="idaText p-block" variants={items} > A virtual assistant (currently undergoing upgrades to its NLU model) 
                that will help you navigate my career and personal experiences. Some of things Ida can do for you include answering questions
                about my work and education, scheduling a Google Calendar Event for us to meet virtually at your preferred
                time, and many more!
                </motion.p>
                <motion.div variants={items} className="button-container">
                    <Link to="/ida" className="button" onClick={handleClick} >Interact</Link>
                </motion.div>
            </motion.div>
            <motion.div variants={image} custom={exitDirection} className="top-img"> </motion.div>
            <motion.div variants={image} custom={exitDirection} className="chat-credit">Credits to Imrah Aliev!</motion.div>
        </motion.div>
    )
}

export default Chat