
import {motion, AnimatePresence} from "framer-motion";
import { useState} from "react";
import {Link} from "react-router-dom";
import {items, text_block, image, notif_obj} from "./const";

import MeetingPage from "./meetings";
import "../static/css/Work.css"

// const resume_host = "http://localhost:8000/resume/";
const resume_host = "https://api.haoxuanliu.com/backend/resume/"; 

function Work({createNotification, updateScroll}) {
    const [exitDirection, setExitDirection] = useState("vertical");
    const [blurBackground, toggleBlur] = useState(false);

    const handleResumeClick = async (evt) =>{
        console.log("querying resume");
        let request_body = {
            "token" : "b15c585e-e022-48ea-9d01-8462fdb2438c"
        }
        try {
            let response = await fetch(resume_host, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify(request_body),
            });
            let res_blob = await response.blob();
            const fileURL = URL.createObjectURL(res_blob);
            window.open(fileURL);
        } catch (e) {
            // prints notification
            notif_obj["title"] = "Error:";
            notif_obj["message"] = e.message;
            notif_obj["type"] = "danger";
            createNotification(notif_obj);
        }

    }
    const handleMeetingClick = (evt) =>{
        toggleBlur(true);
        updateScroll(false);
    }
    // const exitDir = exitDirection.current;
    console.log(`exit dir is ${exitDirection}`);
    return (
        <motion.div className="flex_container about experience"
            initial="hidden"
            animate="show"
            exit="exit"
        >
            <motion.div variants={text_block} className="aboutText">
                <motion.div variants={items} className="aboutContainer">
                    <motion.h4 className="name aboutName" style={{"opacity":0.7}}>Experiences</motion.h4>
                </motion.div> 
                {/* <div className="lines">
                    <span className="underline"></span>
                    <span className="underline"></span>
                </div> */}
                {/* <div className="button-container">
                    <Link to="/Ida" className="button">Interact with Ida</Link>
                </div> */}
                <motion.p className="experienceP p-block" variants={items}>
                    Throughout my time in college, the majority of my work revolves around Machine Learning Architectures, 
                    System Infrastructure, and Web Development. I have worked extensively with state-of-the-art neural 
                    architectures with a focus in 3D reconstruction and object detection, in addition to building industry 
                    level backend solutions that are both scalable and highly available. To learn more about my experiences,
                    click on the "View Resume" button below!
                </motion.p>
                <motion.div variants={items} className="button-container work-button-container">
                    <motion.button className="button work-button" onClick={handleResumeClick} >View Resume</motion.button>
                    <motion.button className="button work-button next-button" onClick={handleMeetingClick} >Create Meeting</motion.button>
                </motion.div>
            </motion.div>
            {/* {/* <motion.div variants={image} custom={exitDirection} className="right-img"></motion.div> */}
            <motion.div variants={image} custom={exitDirection} className="about-image experience-image"> </motion.div>
            <AnimatePresence>
                { blurBackground && 
                    <MeetingPage toggleBlur={toggleBlur} 
                        formState={{
                            "firstName": "",
                            "lastName" : "",
                            "email" : "",
                        }}
                        createNotification={createNotification} updateScroll={updateScroll}
                    />
                }
            </AnimatePresence>
        </motion.div>
    )
}

export default Work