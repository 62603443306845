import React from 'react';
import {motion} from 'framer-motion';
import {square_initial, square_animate, item, container} from './const';

const section_list = ["Home","About","Ida","Work","Contact"]
function Header({page, showProgress, setPage}){
    return (
        <motion.div className="header">
            <motion.div className="menu"
                initial={{opacity :0, y:-200}}
                animate={{ 
                    opacity : 1,
                    y: 0,
                    transition : {
                        delay: 1.2,
                        ease : "easeInOut",
                        duration : 1,
                    }
                }}
            >
                <div className="logo" onClick={()=>setPage(0)}>
                    <p>Haoxuan</p>
                </div>
                <div className="right">
                    <div className="burger">
                        <div className="line1"></div>
                        <div className="line2"></div>
                        <div className="line3"></div>
                    </div>
                </div>
            </motion.div>
            <motion.div className="left-container"
                initial={{opacity :0, x:-200}}
                animate={{ 
                    opacity : 1,
                    x: 0,
                    transition : {
                        ease : "easeInOut",
                        delay: 1.2,
                        duration : 1,
                    }
                }}
            >
                {section_list.map((element, index) => {
                    return (
                        <button className={(index === page) ? "section selected" : "section"} key={index} onClick={()=>setPage(index)}>{element}</button>
                        // <p className={(index === page) ? "section selected" : "section"} key={index}>{element}</p>
                    )
                })}
            </motion.div>
            {showProgress && <motion.div className="right-container"
                variants={container}
                initial="hidden"
                animate="show"
            >

                <motion.div variants={item} className="vl right-line-top"></motion.div>
                <motion.div variants={item} className="vl right-line-bottom"></motion.div>

                <motion.div variants={item} className="progress-bar">
                    {section_list.map((element, index) => {
                        return (
                            <div className="pair">
                                {page === index && 
                                    <motion.div className="square"
                                        initial={square_initial}
                                        animate={square_animate}
                                    ></motion.div>
                                }
                                <motion.div 
                                    className={(page === index) ? "circle hidden" : "circle"}
                                    animate={{ 
                                        opacity : (page === {index}) ? 0 : 1,
                                        transition : {
                                            ease : "easeInOut",
                                            duration : 1,
                                        }
                                    }}
                                >
                                </motion.div>
                            </div>
                            // <p className={(index === page) ? "section selected" : "section"} key={index}>{element}</p>
                        )
                    })}
                </motion.div>
            </motion.div>}
        </motion.div>
    );
}
export default Header;