import { Grid } from "@material-ui/core";
import { alpha } from '@material-ui/core/styles'
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker} from '@material-ui/pickers';

export default function DatePickerUI({handleDateChange, current_date}) {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justifyContent="space-around">
                <KeyboardDatePicker
                    className="date-picker"
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Date:"
                    value={current_date}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                    'aria-label': 'change date',
                    }}
                />
                <KeyboardTimePicker
                    className="time-picker"
                    margin="normal"
                    id="time-picker"
                    label="Time: (PST)"
                    value={current_date}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                    'aria-label': 'change time',
                    }}
                />
            </Grid>
      </MuiPickersUtilsProvider>
    );
}