
import {motion, AnimatePresence} from "framer-motion";
import { useState} from "react";
import {Link} from "react-router-dom";
import {items, text_block, image} from "./const";

import MeetingPage from "./meetings";
import "../static/css/About.css"

function About({setPage, updateScroll, createNotification}) {
    const [exitDirection, setExitDirection] = useState("vertical");
    const [blurBackground, toggleBlur] = useState(false);

    const handleClick = (evt) =>{
        setPage(3); // move to work
    }
    const handleMeetingClick = (evt) =>{
        toggleBlur(true);
        updateScroll(false);
    }
    // const exitDir = exitDirection.current;
    console.log(`exit dir is ${exitDirection}`);
    return (
        <motion.div className="flex_container about"
            initial="hidden"
            animate="show"
            exit="exit"
        >
            <motion.div variants={text_block} className="aboutText">
                <motion.div variants={items} className="aboutContainer">
                    <motion.h4 className="name aboutName" style={{"opacity":0.7}}>About Me</motion.h4>
                </motion.div> 
                {/* <div className="lines">
                    <span className="underline"></span>
                    <span className="underline"></span>
                </div> */}
                {/* <div className="button-container">
                    <Link to="/Ida" className="button">Interact with Ida</Link>
                </div> */}
                <motion.p className="aboutP p-block" variants={items}> Hi there! I'm a Computer Science student interested in Web Development 
                    and Machine Learning. This website hosts many of the projects I have completed over the past few years.
                </motion.p>
                <motion.div variants={items} className="button-container about-container">
                    <motion.button className="button about-button" onClick={handleClick}>Learn More</motion.button>
                    <motion.button className="button about-button next-button-about" onClick={handleMeetingClick}>Let's Chat!</motion.button>
                </motion.div>
            </motion.div>
            {/* {/* <motion.div variants={image} custom={exitDirection} className="right-img"></motion.div> */}
            <motion.div variants={image} custom={exitDirection} className="about-image"> </motion.div>
            <AnimatePresence>
                { blurBackground && 
                    <MeetingPage toggleBlur={toggleBlur} 
                        formState={{
                            "firstName": "",
                            "lastName" : "",
                            "email" : "",
                        }}
                        createNotification={createNotification} updateScroll={updateScroll}
                    />
                }
            </AnimatePresence>
        </motion.div>
    )
}

export default About