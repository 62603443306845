import './static/css/App_test.css';
import './static/css/header.css';
import Contact from './components/contact';
import Landing from './components/Landing';
import Chat from './components/chat';
import Header from './components/header';
import Ida from './components/Ida';
import About from './components/about';
import Work from './components/Work';

import {Switch, Route, useHistory, useLocation} from 'react-router-dom';
import { debounce } from 'lodash';
import {AnimatePresence} from 'framer-motion';
import { useEffect, useState, useMemo, useRef} from 'react';
// react notification
import ReactNotification from 'react-notifications-component';
import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'

function App() {
    const [page, setPage] = useState(0);
    const [customScroll, setScroll] = useState(true);
    const [meetingScroll, toggleScroll] = useState(true); // for meetings
    const scroll_ref = useRef(customScroll);

    const history = useHistory();
    const location = useLocation();
    // const [] = useState();
    function updateScroll (newScroll){
        scroll_ref.current = newScroll;
        setScroll(newScroll);
    }

    const handleWheel = (evt) =>{
        // first check if the control key is pressed
        if (evt.ctrlKey){
            console.log("changing zoom, no page change needed");
            return;
        }    

        if (scroll_ref.current === false){
            console.log("Interacting with ida or submission, no scrolling possible");
            return;
        }
        if (meetingScroll === false){
            console.log("Scheduling meetings, no scrolling possible");
            return;
        }

        let direction = (evt.deltaY < 0 ) ? "up" : "down";  
        // console.log("\nwheel scroll is", direction);

        if (direction === "down" && page !== 4){
            console.log(`scrolling detected, setting page as ${page+1}`);
            setPage((p) => p+1 );
        }
        else if (direction === "up" && page !== 0){
            console.log(`scrolling detected, setting page as ${page-1}`);
            setPage((p) => p-1 );
        }
        else{
            console.log(`keeping page as ${page}`);
        }
    }

    const debouncedWheelHandler = useMemo(
        () => debounce(handleWheel, 200)
    , [page, handleWheel]);
    
    // useEffect(() =>{
    //     setMounted(true);
    // }, []);
    console.log(`rerendered, scroll is ${customScroll}`)
    useEffect(() =>{
        switch(page) {
            case 0:
                // console.log("returning landing on 0");
                history.push("/");
                break;
            case 1:
                history.push("/about");
                break;
            case 2:
                // console.log("returning chat");
                history.push("/chat");
                break;
            case 3:
                history.push("/work");
                break;
            case 4:
                // console.log("returning contact");
                history.push("/contact");
                break;
            default:
                // console.log("returning landing on default ");
                history.push("/");
                break;
        }
    }, [page, history]);

    const addNotification = (customization) =>{
        store.addNotification(customization);
    }
    const history_back = () =>{
        history.goBack();
    }
    return (
        <div onWheel={debouncedWheelHandler} className="App">
            <ReactNotification />
            {scroll_ref.current && <Header page={page} showProgress={customScroll} setPage={setPage} />}
            <AnimatePresence exitBeforeEnter>
                <Switch location={location} key={location.key}>
                    <Route exact path="/" render={(props) => (
                        <Landing {...props} setPage={setPage}/>
                    )}/> 
                    <Route exact path="/about" 
                        render={(props) => (
                            <About {...props} setPage={setPage} updateScroll={toggleScroll} createNotificatio={addNotification}/>
                        )}/> 
                    <Route exact path="/chat" 
                        render={(props) => (
                            <Chat {...props} updateScroll={updateScroll}/>
                        )}/> 
                    <Route exact path="/ida" render={(props) => (
                        <Ida {...props} updateScroll={updateScroll} goBack={history_back}/>
                    )}/>
                    <Route exact path="/work" render={(props) => (
                        <Work {...props} updateScroll={toggleScroll} createNotification={addNotification}/>
                    )}/>
                    <Route exact path="/contact" render={(props) => (
                        <Contact key={"contact-page"} createNotification={addNotification} updateScroll={toggleScroll}/>
                    )}/>
                </Switch>
            </AnimatePresence>
        </div>
    );
}

export default App;
