import { useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {motion} from "framer-motion";
import Message from "./messages";

// import line_top from "../static/img/line_art0.png"
// import line_bottom from "../static/img/line_art0.png"
import '../static/css/ida.css';
// import { initial } from "lodash";

const exit_animation = {
    opacity : 0,
    x: 1000,
    transition: {
        ease: "easeInOut",
        duration: 1,
    }
}
const exit_delay = {
    opacity : 0,
    x: 1000,
    transition: {
        ease: "easeInOut",
        duration: 1,
    }
}
// variants 
const text_block = {
    hidden: {
        opacity : 0,
        y : 100
    },
    show: {
        opacity : 1,
        y : 0,
        transition : { 
            ease: "easeInOut",
            duration : 1,
            delay: 1,
        }
    },
    exit: exit_animation,
    exit_delay: exit_delay
}
const simple_hidden = {
    y: 100,
    opacity: 0,
}
const simple_show = {
    opacity: 1,
    y: 0,
    transition : { 
        ease: "easeInOut",
        duration : 1.5,
        delay: 0.5,
    }
}
const response = {
    hidden : simple_hidden,
    show : {
        opacity: 1,
        y: 0,
        transition : { 
            ease: "easeInOut",
            duration : 1,
        }
    },
    exit : exit_animation,
    exit_delay: exit_delay
}
const wave = {
    hidden : simple_hidden,
    show : {
        opacity: 1,
        y: 0,
        transition : { 
            ease: "easeInOut",
            duration : 1.5,
        }
    },
    exit : {
        opacity : 0,
    }
}
const arrow = {
    hidden : {
        opacity: 0
    },
    show : simple_show,
    exit : {
        opacity : 0,
    }
}
const buttonContainer = {
    hidden : simple_hidden,
    show : {
        opacity: 1,
        y: 0,
        transition : { 
            ease: "easeInOut",
            duration : 1,
            delay: 0.5,
            staggerChildren: 0.2,
        }
    },
    exit: {
        transition: {
            when: "beforeChildren",
            delay: 1, 
        }
    }
}
const button = {
    hidden : {
        opacity: 0,
        y: 100,
    },
    show : {
        opacity: 1,
        y : 0,
        transition : { 
            ease: "easeInOut",
            duration : 1
        }
    },
    exit : {
        opacity: 0,
    }
}

function Ida({updateScroll, goBack}) {
    const [messages, setMessages] = useState([]);
    const [text, setText] = useState("");
    const [buttons, setButtons] = useState([]);
    // const [uuid, setuuid] = useState(0);
    // const host = "http://localhost:5005/webhooks/rest/webhook/"; // Rasa bot address
    const host = "https://ida.haoxuanliu.com/webhooks/rest/webhook/"; // Rasa bot address

    useEffect(() => {
        messageRequest("initial"); // get initial_greetings
        // return (() => )
    },[]);
    
    useEffect(() => {
        console.log("messages is now ", messages);
    },[messages]);


    const messageRequest = (message_text="") => {
        let request_message = message_text;
        if (message_text === "initial"){
            request_message = "/get_initial_greeting";
        }
        else if(message_text === ""){
            request_message = text;
        }
        console.log(`request message to be sent is ${request_message}`);
        let requestBody = {
            "sender" : "test_user",
            "message" : request_message, 
        }
        // queue to start the animation
        // remove the animation a second after the message is received
        fetch(host,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            }
        )
        .then(res => res.json())
        .then(
            (result) => {
                console.log(result);
                if (result.length === 0){
                    console.log("Error retrieving response from Rasa");
                }
                else{
                    setMessages((messages) => [
                        ...messages,
                        {
                            "text" : result[0]["text"],
                            "sender" : "Ida",
                        }
                    ]);
                    if (result[0].hasOwnProperty("buttons")){
                        setButtons(result[0]["buttons"]);
                    }
                }
            },
            (error) => {
                console.log("Error decoding json, improper response");
            }
        );
    }
    const handleSubmit = (evt) => {
        // query response
        messageRequest();
        // reset the user text
        console.log("resetting text ")
        setText("");
        // reset the buttons
        setButtons([])
        evt.preventDefault();
    }

    const handleChange = (evt) => {
        setText(evt.target.value);
    }

    const handleButtonClick = (payload) => (evt) =>{
        // send back the response with proper paylaod
        console.log(`payload of the button clicked is ${payload}`);
        messageRequest(payload);
        // console.log(evt);
        setText("");
        // reset the buttons
        console.log("unmounted buttons")
        setButtons([])
    }
    const isUninterested = (button) =>{
        return button["payload"] === "/inform_uninterested{\"redirect_target\": \"uninterested\"}";
    }
    const last_msg = messages[messages.length-1];
    // const uninterested_button = useMemo(() => (buttons.length > 0) 
    //     ? buttons.find(button => button["payload"] === "/inform_uninterested{\"redirect_target\": \"uninterested\"}")
    //     : undefined, [buttons]);
    // const redirect_buttons = useMemo(
    //     () => ((buttons.length > 0) 
    //         ? buttons.filter(button => button["payload"] !== "/inform_uninterested{\"redirect_target\": \"uninterested\"}")
    //         : undefined
    //     ),[buttons]
    // );
    // console.log("text is", text);
    return (
        <motion.div
            initial="hidden"
            animate="show"
            exit={buttons.length === 0 ? "exit" : "exit_delay"}
        >
            <motion.div variants={arrow} className="arrow-container">
                <motion.button className="arrow" style={{color: "black"}} onClick={() => {updateScroll(true); goBack();}}>&larr;</motion.button>
            </motion.div>
            {/* <motion.div className="response-container">
                <motion.img variants={art_top} className="line_art" src={line_top}></motion.img>
                {(messages.length > 0) 
                    ? <motion.div variants={response}><Message body={last_msg["body"]} creator={last_msg["creator"]} /></motion.div>
                    : <motion.h4 className="message-filler"></motion.h4>
                }
                <motion.img variants={art_bottom} className="line_art" src={line_bottom}></motion.img>
            </motion.div> */}
            <motion.div className="ida">
                <motion.div className="response-container">
                    {(messages.length > 0) 
                        ? <motion.div key={last_msg["text"]} variants={response}><Message key={last_msg["text"]} body={last_msg["text"]} creator={last_msg["sender"]} /></motion.div>
                        : <motion.h4 className="message-filler"></motion.h4>
                    }
                </motion.div>
                <motion.div variants={text_block} className="chat-container">
                    {/* {messages.map((message, index) =>
                        <Message key={index} body={message["body"]} creator={message["creator"]} />
                    )} */}
                    <div className="text-bar">
                        <form onSubmit={handleSubmit}>
                            <input className="text" type="text" name="" placeholder="Type to chat" value={text} onChange={handleChange}></input>
                            <button type="submit" className="send-btn">
                                <i className="fas fa-search"></i>
                            </button>
                        </form>
                    </div>
                    {buttons.length > 0 && 
                        <motion.div className="button-bar" variants={buttonContainer}>
                            {buttons.map((button, index) => {
                                let class_name = isUninterested(button) ? "rasabutton uninterested" : "rasabutton";
                                return <motion.button className={class_name} key={button["title"]} to="/chat" onClick={handleButtonClick(button["payload"])}
                                    initial={{opacity:0, y: 100}}
                                    animate={{
                                        opacity:1,
                                        y:0, 
                                        transition: {
                                            ease: "easeInOut",
                                            duration : 1,
                                            delay: index*0.1,
                                        }
                                    }}
                                    exit = {{
                                        opacity:0,
                                        transition: {
                                            ease: "easeInOut",
                                            duration : 0.5,
                                            delay: index*0.1,
                                        }
                                    }}
                                >
                                    {button["title"]} 
                                </motion.button>
                            })}
                            {/* // <motion.button to="/" className="rasabutton uninterested" variants={button}
                            //     onClick={handleButtonClick(uninterested_button["payload"])}
                            // >
                            //     {uninterested_button["title"]}
                            // </motion.button> */}
                        </motion.div>
                    }
                    {/* <form onSubmit={handleSubmit}>
                        <input type="text" value={text} onChange={handleChange} placeholder="enter your text here" />
                        <input type="submit" value="Send" />
                    </form> */}
                </motion.div>
            </motion.div>
            <motion.div variants={wave} initial="hidden" animate="show" exit="exit" className="waveWrapper waveAnimation">
                <div className="waveWrapperInner bgTop">
                    <div className="wave waveTop"></div>
                </div>
                <div className="waveWrapperInner bgMiddle">
                    <div className="wave waveMiddle"></div>
                </div>
                <div className="waveWrapperInner bgBottom">
                    <div className="wave waveBottom"></div>
                </div>
            </motion.div>
        </motion.div>
        // <div className="contact">
        //     <div className="contact-left">
        //         <p> Contact </p>
        //     </div>
        // </div>
    )
}

export default Ida