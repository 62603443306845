import { useState } from "react"
const Message = ({body, creator}) => {
    const [text, setText] = useState(body);
    return (
        <div className={`message ${creator}`}>
            <p>{text}</p>
        </div>
        // <div className="contact">
        //     <div className="contact-left">
        //         <p> Contact </p>
        //     </div>
        // </div>
    )
}

export default Message