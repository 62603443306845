import React from 'react';
import {motion} from 'framer-motion';
import {Link} from 'react-router-dom';

// import background from '../static/img/mountain.jpg';

// const transition = {duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96]};

// variants 
const text_block = {
    hidden: {
        opacity : 0,
    },
    show: {
        opacity : 0.85,
        transition : { 
            ease: "easeInOut",
            duration : 0.6,
            staggerChildren: 0.1,
        }
    },
    exit: {
        opacity : 0,
        transition: {
            ease: "easeInOut",
            duration: 1.5,
            staggerChildren: 0.05,
        }
    }
}
const items = {
    hidden: {
        opacity : 0,
        x : -200
    },
    show: {
        opacity : 0.85,
        x : 0,
        transition : { 
            ease: "easeInOut",
            duration : 0.7,
        }
    },
    exit: {
        opacity : 0,
        x : 700,
        transition: {
            ease: "easeInOut",
            duration: 1,
        }
    }

}
function Landing({setPage}){
    return (
        <>
            {/* <AnimatePresence initial={false}> */}
                <motion.div className="background-img"
                    animate={{
                        opacity:1,
                        transition : { 
                            ease: "easeInOut",
                            duration : 0.7,
                        }
                    }}
                    exit={{
                        opacity:0,
                        x:700,
                        transition : { 
                            ease: "easeInOut",
                            duration : 1,
                        }
                    }}
                ></motion.div>
            {/* </AnimatePresence> */}
            <motion.div className="flex_container"
                initial="hidden"
                animate="show"
                exit="exit"
                variants={text_block}
            >
                <motion.div variants={items} className="name">
                    <h1> Haoxuan Liu</h1>
                </motion.div>
                {/* <div className="lines">
                    <span className="underline"></span>
                    <span className="underline"></span>
                </div> */}
                <motion.div variants={items} className="button-container">
                    <motion.button className="button landing-button" onClick={()=>{setPage(1)}}>Explore</motion.button>
                </motion.div>
                <motion.p variants={items} className="flex-p">Web Developer</motion.p>
                <motion.p variants={items} className="flex-p">Machine Learning Engineer</motion.p>
                <motion.p variants={items} className="flex-p">System Infrastructure</motion.p>
                {/* <p> I'm a Computer Science student interested in Web Development <br /> 
                    and Machine Learning. This website hosts many of the projects <br />
                    I have completed over the past few years.
                </p> */}
            </motion.div>
            {/* <img className="landingImg" src={background} alt={""}></img> */}

        </>
    )
}

export default Landing;