import {motion, AnimatePresence} from "framer-motion";
import { useState, createRef} from "react";
import {fadeInOut, slowAppear} from "./const";
import ReCAPTCHA from "react-google-recaptcha";
import moment from "moment";

import {notif_obj} from './const';
import DatePickerUI from "./date_picker.js";
import "../static/css/meeting.css"


const recaptchaRef = createRef();
const MeetingPage = ({toggleBlur, formState, createNotification, updateScroll}) => {
    const [captchaToken, setCaptchaToken] = useState(null);
    
    const [formValues, setFormValues] = useState({
        "firstName": formState.firstName,
        "lastName" : formState.lastName,
        "email" : formState.email,
        "note" : "",
    })
    const [meetingDateTime, setMeetingDateTime] = useState(new Date()); // sets the date to now
    const [scheduledMeeting, setScheduledMeeting] = useState(0);

    // const meeting_host = "http://localhost:8000/meeting/";
    const meeting_host = "https://api.haoxuanliu.com/backend/meeting/";

    async function handleScheduleMeeting(evt){
        // cancel event:
        if (evt.target.id === "cancel"){
            toggleBlur(false);
            updateScroll(true);
            return;
        }
        // set schedule meeting to 1 to begin loading screen
        setScheduledMeeting(1);
        // verify that the user is allowed to submit
        if (captchaToken !== null){
            console.log("submitting user form:", formValues);
            // create json with the form information
            let request_body = {
                "dateTime" : moment(meetingDateTime).format('YYYY-MM-DD HH:mm'),
                "token" : captchaToken,
                ...formValues,
            }
            console.log(request_body);
            let response = await fetch(meeting_host, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify(request_body),
            });
            let res_json = await response.json();
            // success or fail:
            let res_message = res_json["body"]
            // create a notification based on response
            if (res_message === "Success"){
                notif_obj["title"] = "Success!";
                notif_obj["message"] = "Form submitted successfully. A confirmation has been sent to your email address";
                notif_obj["type"] = "success";
                // clear the inputs
                setFormValues({
                    "firstName": "",
                    "lastName" : "",
                    "email" : "",
                    "note" : "",
                })
                // show success message in the middle, make go back button appear
            }
            else{
                notif_obj["title"] = "Error:";
                notif_obj["message"] = res_message;
                notif_obj["type"] = "danger";
            }
            createNotification(notif_obj);
            console.log("event target is ", evt.target)
            recaptchaRef.current.reset();
            setCaptchaToken(null);

            setScheduledMeeting(2); // scheduling finished
            // show previous state
            if (res_message === "Success"){
                setTimeout(function(){ 
                    updateScroll(true);
                    toggleBlur(false);
                }, 500);  
            }
        }
        else{
            // display error message
            notif_obj["title"] = "Error:";
            notif_obj["message"] = "Please complete the Captcha challenge to verify your identity";
            notif_obj["type"] = "danger";
            createNotification(notif_obj);
        }
    }

    async function handleDateTimeChange(new_date){
        setMeetingDateTime(new_date);
    }

    async function onCaptcha(value){
        console.log("Captcha value:", value);
        if (value === null){
            setCaptchaToken(null); // session expired
            console.log("captcha session expired");
            return;
        }
        else{
            setCaptchaToken(value);
            console.log("user has completed captcha");
        }
    }
    const handleChange = (evt) => {
        const target_element = evt.target.name;
        if (target_element === "firstName"){
            setFormValues({
                ...formValues,
                "firstName": evt.target.value,
            })
        }
        else if (target_element === "lastName"){
            setFormValues({
                ...formValues,
                "lastName": evt.target.value,
            })
        }
        else if (target_element === "email"){
            setFormValues({
                ...formValues,
                "email": evt.target.value,
            })
        }
        else{
            // target_element === "message"
            setFormValues({
                ...formValues,
                "note": evt.target.value,
            })
        }
    }

    return (
        <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
        >
            <motion.div key={"blur"} className="blur" variants={fadeInOut}></motion.div> 
            <motion.div key={"meetings"} className="meetings-page" variants={slowAppear}>
                <h4 className="meeting-title">Schedule Meeting:</h4>
                <div className="meeting-row">
                    <div className="meeting-div">
                        <p className="form-field-label required">
                            First Name
                        </p>
                        <input type="text" name="firstName" className="form-field-input meeting-field" value={formValues["firstName"]} placeholder="Enter your first name" onChange={handleChange} required></input>
                    </div>
                    <div className="meeting-div">
                        <p className="form-field-label required">
                            Last Name
                        </p>
                        <input type="text" name="lastName" className="form-field-input meeting-field" value={formValues["lastName"]} placeholder="Enter your last name" onChange={handleChange} required></input>
                    </div>
                </div>
                <div className="meeting-row">
                    <div className="medium-length meeting-div">
                        <p className="form-field-label required">
                            Email
                        </p>
                        <input type="text" name="email" className="form-field-input meeting-field full-length" value={formValues["email"]} placeholder="your@your.email" onChange={handleChange} required></input>
                    </div>
                </div>
                <div className="date-picker-div">
                    <DatePickerUI handleDateChange={handleDateTimeChange} current_date={meetingDateTime}></DatePickerUI>
                </div>
                <div className="meeting-row">
                    <div className="medium-high-length meeting-div">
                        <p className="form-field-label">
                            Message:
                        </p>
                        <textarea className="meeting-message" name="message" placeholder="Meeting notes" value={formValues["note"]} 
                            onChange={handleChange}>
                        </textarea>
                    </div>
                </div>
                <div className="meeting-row">
                    <div className="captcha-div">
                        <ReCAPTCHA className="captcha" ref={recaptchaRef} sitekey="6Ld3460bAAAAAEyhIa20DldNRxLHwKbvaieqhig6" onChange={onCaptcha} required/>
                    </div>
                </div>
                <div className="button-row">
                    <button id="schedule" className="schedule-meeting-button contact-button" onClick={handleScheduleMeeting}>Schedule</button>
                    <button id="cancel" className="cancel-button contact-button" onClick={handleScheduleMeeting}>Cancel</button>
                </div>
            </motion.div>

        </motion.div>
    );
}
export default MeetingPage