// Object Constants
export const notif_obj = {
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 4000,
        onScreen: true,
    },
    width: 600,
    pauseOnHover: true
}
// <---------------------------- Header Transitions ---------------------------->
export const square_initial = {
    opacity: 0.2
}
export const square_animate = {
    opacity : 1,
    transition : {
        ease : "easeInOut",
        duration : 1,
    }
}
export const container = {
    hidden: {
        opacity : 0,
    },
    show: {
        opacity : 0.8,
        transition : { 
            ease: "easeInOut",
            duration : 0.6,
            staggerChildren: 0.5
        }
    }
}
export const item = {
    hidden: {
        opacity : 0,
        x : 200,
    },
    show: {
        opacity : 0.8,
        x : 0,
        transition : {
            ease: "easeInOut",
            duration : 1,
        }
    }
}

// <---------------------------- Chat Transitions ---------------------------->
export const items = {
    hidden: {
        opacity : 0,
        x : -400
    },
    show: {
        x : 0,
        opacity : 1,
        transition : { 
            ease: "easeInOut",
            duration : 1,
        }
    },
    exit: {
        opacity:0,
        x : 1000,
        transition : { 
            ease: "easeInOut",
            duration : 1.5,
        }
    }
}
export const text_block = {
    hidden: {
        opacity:0,
    },
    show: {
        opacity: 1,
        transition : { 
            ease: "easeInOut",
            duration : 1,
            staggerChildren: 0.2,
        }
    },
    // exit: (exitDir) => (
    //     {
    //         opacity:0,
    //         x : -1000,
    //         transition : { 
    //             staggerChildren: 0.2, 
    //         }
    //     }
    // )
    exit: {
        opacity : 0,
        transition: {
            ease: "easeInOut",
            duration : 1,
            staggerChildren: 0.05,
        }
    }
}
export const image = {
    hidden :{
        x: -400
    },
    show :{
        x : 0,
        opacity:1,
        transition : { 
            ease: "easeInOut",
            duration : 0.7,
            delay: 0.5,
        }
    },
    exit : (exitDir) => {
        return {
            opacity:0,
            x : 1000,
            transition : { 
                ease: "easeInOut",
                duration : 1.5,
            }
        } 
    }
}
// <---------------------------- Contact Transitions ---------------------------->
export const fast_transition = {
    ease: "easeInOut",
    duration : 1.2,
}
export const delayed_transition = {
    ease: "easeInOut",
    duration : 1,
    delay: 0.8,
}

export const fadeInOut = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
        transition: {
            ease: "easeInOut",
            duration: 0.7,
        }
    },
    exit : {
        opacity: 0,
        transition: {
            ease: "easeInOut",
            duration: 0.7,
            delay: 0.5,
        }
    }
}
export const slowAppear = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
        transition: {
            ease: "easeInOut",
            duration: 1,
            delay: 0.6,
        }
    },
    exit : {
        opacity: 0,
        transition: {
            ease: "easeInOut",
            duration: 0.7,
        }
    }
}
export const variant_form = {
    initial_right: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
        transition: {
            when: "beforeChildren",
            staggerChildren: 0.05,
            delay: 0.5,
        }
    },
}
export const variant_center = {
    initial_left: {
        opacity: 0,
        x: -300,
    },
    initial_right: {
        opacity: 0,
        x: 500,
    },
    animate: {
        x: 0,
        opacity: 1,
        transition: fast_transition
    },
    animate_delayed: {
        x: 0,
        opacity: 1,
        transition: delayed_transition
    },
    exit_left: {
        opacity: 0,
        x: -500,
        transition: fast_transition,
    },
    exit_right: {
        opacity: 0,
        x: 1000,
        transition: fast_transition
    }
}
export const variant_line = {
    initial_top: {
        opacity: 0,
        y : 350,
    },
    initial_bottom: {
        opacity: 0,
        y: -350,
    },
    animate:{
        opacity: 1,
        y: 0,
        transition: fast_transition
    },
    exit_top: {
        opacity: 0,
        y: 350,
        transition: delayed_transition
    },
    exit_bottom: {
        opacity: 0,
        y : -350,
        transition: delayed_transition
    }
}


// const art_top = {
//     hidden : {
//         opacity: 0,
//         y : 100
//     },
//     show : { 
//         opacity: 0.7,
//         y: 0,
//         transition : { 
//             ease: "easeInOut",
//             duration : 1
//         }
//     },
//     exit: exit_animation
// }
// const art_bottom = {
//     hidden : {
//         opacity: 0,
//         y : -100
//     },
//     show : { 
//         opacity: 0.7,
//         y: 0,
//         transition : { 
//             ease: "easeInOut",
//             duration : 1
//         }
//     },
//     exit: exit_animation
// }